html {
  @include fadeIn($duration: 1.2s, $function: ease, $fill: both);
}

html,
body {
  height: 100%;
}

body {
  padding: var(--gap);
  color: var(--foreground);
  background: var(--background-color);
  display: flex;

  .wrap {
    @include grid;

    margin: auto;
    max-width: 50rem;
    max-width: 60ch;
  }
}

svg {
  pointer-events: none;
}

a {
  color: var(--link);

  .wrap > header & {
    text-decoration: none;
  }

  &:hover {
    color: var(--link-higher);
  }
}

a {
  .wrap main & {
    border-bottom: 0.1rem solid var(--primary);
    box-shadow: 0 -0.1rem 0 var(--primary) inset;
    transition: background 0.3s cubic-bezier(0.33, 0.66, 0.66, 1) 0s;
  }
}

.wrap > footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 90%;
}

// home
.home,
.startseite {
  main {
    @include grid;

    gap: var(--gap);

    @include respond-to("small") {
      grid-template-columns: 1fr 3fr;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    margin: auto;

    // border-radius: 47% 53% 67% 33% / 45% 37% 63% 55%;
    border-radius: var(--radius-blob-2);
    transition: all 1s ease-in-out;

    &:hover {
      border-radius: var(--radius-blob-3);
    }

    :lang(de) & {
      // border-radius: 61% 39% 59% 41% / 60% 53% 47% 40%;
      border-radius: var(--radius-blob-4);

      &:hover {
        border-radius: var(--radius-blob-1);
      }
    }
  }
}
