:where(html) {
  --size-000: -.5rem;
  --size-00: -.25rem;
  --size-1: .25rem;
  --size-2: .5rem;
  --size-3: 1rem;
  --size-4: 1.25rem;
  --size-5: 1.5rem;
  --size-6: 1.75rem;
  --size-7: 2rem;
  --size-8: 3rem;
  --size-9: 4rem;
  --size-10: 5rem;
  --size-11: 7.5rem;
  --size-12: 10rem;
  --size-13: 15rem;
  --size-14: 20rem;
  --size-15: 30rem;
  --size-px-000: -8px;
  --size-px-00: -4px;
  --size-px-1: 4px;
  --size-px-2: 8px;
  --size-px-3: 16px;
  --size-px-4: 20px;
  --size-px-5: 24px;
  --size-px-6: 28px;
  --size-px-7: 32px;
  --size-px-8: 48px;
  --size-px-9: 64px;
  --size-px-10: 80px;
  --size-px-11: 120px;
  --size-px-12: 160px;
  --size-px-13: 240px;
  --size-px-14: 320px;
  --size-px-15: 480px;
  --size-fluid-1: clamp(.5rem, 1vw, 1rem);
  --size-fluid-2: clamp(1rem, 2vw, 1.5rem);
  --size-fluid-3: clamp(1.5rem, 3vw, 2rem);
  --size-fluid-4: clamp(2rem, 4vw, 3rem);
  --size-fluid-5: clamp(4rem, 5vw, 5rem);
  --size-fluid-6: clamp(5rem, 7vw, 7.5rem);
  --size-fluid-7: clamp(7.5rem, 10vw, 10rem);
  --size-fluid-8: clamp(10rem, 20vw, 15rem);
  --size-fluid-9: clamp(15rem, 30vw, 20rem);
  --size-fluid-10: clamp(20rem, 40vw, 30rem);
  --size-content-1: 20ch;
  --size-content-2: 45ch;
  --size-content-3: 60ch;
  --size-header-1: 20ch;
  --size-header-2: 25ch;
  --size-header-3: 35ch;
  --size-xxs: 240px;
  --size-xs: 360px;
  --size-sm: 480px;
  --size-md: 768px;
  --size-lg: 1024px;
  --size-xl: 1440px;
  --size-xxl: 1920px;
  --size-relative-000: -.5ch;
  --size-relative-00: -.25ch;
  --size-relative-1: .25ch;
  --size-relative-2: .5ch;
  --size-relative-3: 1ch;
  --size-relative-4: 1.25ch;
  --size-relative-5: 1.5ch;
  --size-relative-6: 1.75ch;
  --size-relative-7: 2ch;
  --size-relative-8: 3ch;
  --size-relative-9: 4ch;
  --size-relative-10: 5ch;
  --size-relative-11: 7.5ch;
  --size-relative-12: 10ch;
  --size-relative-13: 15ch;
  --size-relative-14: 20ch;
  --size-relative-15: 30ch;
}

:where(html) {
  --font-system-ui: system-ui, sans-serif;
  --font-transitional: Charter, Bitstream Charter, Sitka Text, Cambria, serif;
  --font-old-style: Iowan Old Style, Palatino Linotype, URW Palladio L, P052, serif;
  --font-humanist: Seravek, Gill Sans Nova, Ubuntu, Calibri, DejaVu Sans, source-sans-pro, sans-serif;
  --font-geometric-humanist: Avenir, Montserrat, Corbel, URW Gothic, source-sans-pro, sans-serif;
  --font-classical-humanist: Optima, Candara, Noto Sans, source-sans-pro, sans-serif;
  --font-neo-grotesque: Inter, Roboto, Helvetica Neue, Arial Nova, Nimbus Sans, Arial, sans-serif;
  --font-monospace-slab-serif: Nimbus Mono PS, Courier New, monospace;
  --font-monospace-code: Dank Mono,Operator Mono, Inconsolata, Fira Mono, ui-monospace, SF Mono, Monaco, Droid Sans Mono, Source Code Pro, Cascadia Code, Menlo, Consolas, DejaVu Sans Mono, monospace;
  --font-industrial: Bahnschrift, DIN Alternate, Franklin Gothic Medium, Nimbus Sans Narrow, sans-serif-condensed, sans-serif;
  --font-rounded-sans: ui-rounded, Hiragino Maru Gothic ProN, Quicksand, Comfortaa, Manjari, Arial Rounded MT, Arial Rounded MT Bold, Calibri, source-sans-pro, sans-serif;
  --font-slab-serif: Rockwell, Rockwell Nova, Roboto Slab, DejaVu Serif, Sitka Small, serif;
  --font-antique: Superclarendon, Bookman Old Style, URW Bookman, URW Bookman L, Georgia Pro, Georgia, serif;
  --font-didone: Didot, Bodoni MT, Noto Serif Display, URW Palladio L, P052, Sylfaen, serif;
  --font-handwritten: Segoe Print, Bradley Hand, Chilanka, TSCu_Comic, casual, cursive;
  --font-sans: var(--font-system-ui);
  --font-serif: ui-serif, serif;
  --font-mono: var(--font-monospace-code);
  --font-weight-1: 100;
  --font-weight-2: 200;
  --font-weight-3: 300;
  --font-weight-4: 400;
  --font-weight-5: 500;
  --font-weight-6: 600;
  --font-weight-7: 700;
  --font-weight-8: 800;
  --font-weight-9: 900;
  --font-lineheight-00: .95;
  --font-lineheight-0: 1.1;
  --font-lineheight-1: 1.25;
  --font-lineheight-2: 1.375;
  --font-lineheight-3: 1.5;
  --font-lineheight-4: 1.75;
  --font-lineheight-5: 2;
  --font-letterspacing-0: -.05em;
  --font-letterspacing-1: .025em;
  --font-letterspacing-2: .050em;
  --font-letterspacing-3: .075em;
  --font-letterspacing-4: .150em;
  --font-letterspacing-5: .500em;
  --font-letterspacing-6: .750em;
  --font-letterspacing-7: 1em;
  --font-size-00: .5rem;
  --font-size-0: .75rem;
  --font-size-1: 1rem;
  --font-size-2: 1.1rem;
  --font-size-3: 1.25rem;
  --font-size-4: 1.5rem;
  --font-size-5: 2rem;
  --font-size-6: 2.5rem;
  --font-size-7: 3rem;
  --font-size-8: 3.5rem;
  --font-size-fluid-0: clamp(.75rem, 2vw, 1rem);
  --font-size-fluid-1: clamp(1rem, 4vw, 1.5rem);
  --font-size-fluid-2: clamp(1.5rem, 6vw, 2.5rem);
  --font-size-fluid-3: clamp(2rem, 9vw, 3.5rem);
}

:where(html) {
  --border-size-1: 1px;
  --border-size-2: 2px;
  --border-size-3: 5px;
  --border-size-4: 10px;
  --border-size-5: 25px;
  --radius-1: 2px;
  --radius-2: 5px;
  --radius-3: 1rem;
  --radius-4: 2rem;
  --radius-5: 4rem;
  --radius-6: 8rem;
  --radius-drawn-1: 255px 15px 225px 15px / 15px 225px 15px 255px;
  --radius-drawn-2: 125px 10px 20px 185px / 25px 205px 205px 25px;
  --radius-drawn-3: 15px 255px 15px 225px / 225px 15px 255px 15px;
  --radius-drawn-4: 15px 25px 155px 25px / 225px 150px 25px 115px;
  --radius-drawn-5: 250px 25px 15px 20px / 15px 80px 105px 115px;
  --radius-drawn-6: 28px 100px 20px 15px / 150px 30px 205px 225px;
  --radius-round: 1e5px;
  --radius-blob-1: 30% 70% 70% 30% / 53% 30% 70% 47%;
  --radius-blob-2: 53% 47% 34% 66% / 63% 46% 54% 37%;
  --radius-blob-3: 37% 63% 56% 44% / 49% 56% 44% 51%;
  --radius-blob-4: 63% 37% 37% 63% / 43% 37% 63% 57%;
  --radius-blob-5: 49% 51% 48% 52% / 57% 44% 56% 43%;
  --radius-conditional-1: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-1));
  --radius-conditional-2: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-2));
  --radius-conditional-3: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-3));
  --radius-conditional-4: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-4));
  --radius-conditional-5: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-5));
  --radius-conditional-6: clamp(0px, calc(100vw - 100%) * 1e5, var(--radius-6));
}

:where(html) {
  --gray-0: #f8f9fa;
  --gray-1: #f1f3f5;
  --gray-2: #e9ecef;
  --gray-3: #dee2e6;
  --gray-4: #ced4da;
  --gray-5: #adb5bd;
  --gray-6: #868e96;
  --gray-7: #495057;
  --gray-8: #343a40;
  --gray-9: #212529;
  --gray-10: #16191d;
  --gray-11: #0d0f12;
  --gray-12: #030507;
}

:root {
  --gap: 1rem;
  --col: 15rem;
  --accent: var(--gray-8);
  --font-family: var(--font-sans);
  --transparent-white: rgb(255 255 255 / 50%);
  --animation-duration: 1s;
  --loader-begin: 1.5s;
  --loader-duration: 3s;
  color-scheme: light dark;
  accent-color: var(--accent);
}

@media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
  :root {
    --accent: var(--gray-8);
    --border-color: var(--gray-3);
    --link: var(--gray-7);
    --link-higher: var(--gray-7);
    --background-color: var(--gray-0);
    --background-content: #fff;
    --foreground: var(--gray-8);
    --middleground: var(--gray-5);
    --background-higher: var(--bs-white);
    --foreground-higher: var(--gray-9);
    --foreground-lower: var(--gray-7);
  }
}
@media (prefers-color-scheme: dark) {
  :root {
    --accent: var(--gray-1);
    --border-color: var(--gray-6);
    --link: var(--gray-0);
    --link-higher: var(--gray-1);
    --background-color: #4b5766;
    --background-content: #48596d;
    --foreground: var(--gray-0);
    --middleground: var(--gray-5);
    --background-higher: var(--gray-9);
    --foreground-higher: var(--bs-white);
    --foreground-lower: var(--gray-1);
  }
}
[data-theme=light] {
  --accent: var(--gray-8);
  --border-color: var(--gray-3);
  --link: var(--gray-7);
  --link-higher: var(--gray-6);
  --background-color: var(--gray-0);
  --background-content: #fff;
  --foreground: var(--gray-8);
  --middleground: var(--gray-5);
  --background-higher: var(--bs-white);
  --foreground-higher: var(--gray-9);
  --foreground-lower: var(--gray-7);
}

[data-theme=dark] {
  --accent: var(--gray-1);
  --border-color: var(--gray-6);
  --link: var(--gray-0);
  --link-higher: var(--gray-1);
  --background-color: #4b5766;
  --background-content: #48596d;
  --foreground: var(--gray-2);
  --middleground: var(--gray-5);
  --background-higher: var(--gray-9);
  --foreground-higher: var(--bs-white);
  --foreground-lower: var(--gray-1);
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.45;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-bottom: 1rem;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
@font-face {
  font-family: GeneralSans;
  src: url("../font/General_Sans/GeneralSans-Extralight.woff2") format("woff2"), url("../font/General_Sans/GeneralSans-Extralight.woff") format("woff");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: GeneralSans;
  src: url("../font/General_Sans/GeneralSans-Regular.woff2") format("woff2"), url("../font/General_Sans/GeneralSans-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
body {
  font-feature-settings: "liga", "clig", "kern";
  font-variant-ligatures: common-ligatures;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: GeneralSans, var(--font-sans);
  font-size: var(--font-size-fluid-1);
  font-weight: 200;
}

h1,
h2,
h3 {
  font-weight: 400;
}

a[href^="mailto:"] {
  hyphens: none;
}

p {
  text-align: justify;
  hyphens: auto;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.theme-toggle {
  --size: var(--size-5);
  background: none;
  border: none;
  padding: 0;
  inline-size: var(--size);
  block-size: var(--size);
  aspect-ratio: 1;
  border-radius: 50%;
  cursor: pointer;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  outline-offset: 5px;
}
.theme-toggle > svg {
  inline-size: 100%;
  block-size: 100%;
  stroke-linecap: round;
}
@media (hover: none) {
  .theme-toggle {
    --size: var(--size-6);
  }
}

.sun-and-moon > :is(.moon, .sun, .sun-beams) {
  transform-origin: center center;
}
.sun-and-moon > :is(.moon, .sun) {
  fill: var(--foreground);
}
.theme-toggle:is(:hover, :focus-visible) > .sun-and-moon > :is(.moon, .sun) {
  fill: var(--foreground-higher);
}

.sun-and-moon > .sun-beams {
  stroke: var(--foreground);
  stroke-width: 2px;
}
.theme-toggle:is(:hover, :focus-visible) .sun-and-moon > .sun-beams {
  stroke: var(--foreground-higher);
}

[data-theme=dark] .sun-and-moon > .sun {
  transform: scale(1.75);
}
[data-theme=dark] .sun-and-moon > .sun-beams {
  opacity: 0;
}
[data-theme=dark] .sun-and-moon > .moon > circle {
  transform: translateX(-7px);
}
@supports (cx: 1) {
  [data-theme=dark] .sun-and-moon > .moon > circle {
    transform: translateX(0);
    cx: 17;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .sun-and-moon > .sun {
    transition: transform 0.5s var(--ease-elastic-3);
  }
  .sun-and-moon > .sun-beams {
    transition: transform 0.5s var(--ease-elastic-4), opacity 0.5s var(--ease-3);
  }
  .sun-and-moon .moon > circle {
    transition: transform 0.25s var(--ease-out-5);
  }
  @supports (cx: 1) {
    .sun-and-moon .moon > circle {
      transition: cx 0.25s var(--ease-out-5);
    }
  }
  [data-theme=dark] .sun-and-moon > .sun {
    transform: scale(1.75);
    transition-timing-function: var(--ease-3);
    transition-duration: 0.25s;
  }
  [data-theme=dark] .sun-and-moon > .sun-beams {
    transform: rotateZ(-25deg);
    transition-duration: 0.15s;
  }
  [data-theme=dark] .sun-and-moon > .moon > circle {
    transition-delay: 0.25s;
    transition-duration: 0.5s;
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: var(--gap);
  align-content: flex-start;
}
@supports (grid-area: auto) {
  .grid {
    display: grid;
  }
}

.grid-auto {
  flex-direction: row;
  flex-wrap: wrap;
}
@supports (grid-area: auto) {
  .grid-auto {
    grid-template-columns: repeat(auto-fill, minmax(var(--col), 1fr));
  }
}

.grid-auto-fit {
  flex-direction: row;
  flex-wrap: wrap;
}
@supports (grid-area: auto) {
  .grid-auto-fit {
    grid-template-columns: repeat(auto-fit, minmax(var(--col), 1fr));
  }
}

.grid-reset {
  grid-column: -1/1;
}

html {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -ms-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-iteration-count: 1s;
  -moz-animation-iteration-count: 1s;
  -ms-animation-iteration-count: 1s;
  -o-animation-iteration-count: 1s;
  animation-iteration-count: 1s;
  -webkit-animation-duration: 1.2s;
  -moz-animation-duration: 1.2s;
  -ms-animation-duration: 1.2s;
  -o-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}

html,
body {
  height: 100%;
}

body {
  padding: var(--gap);
  color: var(--foreground);
  background: var(--background-color);
  display: flex;
}
body .wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: var(--gap);
  align-content: flex-start;
  margin: auto;
  max-width: 50rem;
  max-width: 60ch;
}
@supports (grid-area: auto) {
  body .wrap {
    display: grid;
  }
}

svg {
  pointer-events: none;
}

a {
  color: var(--link);
}
.wrap > header a {
  text-decoration: none;
}
a:hover {
  color: var(--link-higher);
}

.wrap main a {
  border-bottom: 0.1rem solid var(--primary);
  box-shadow: 0 -0.1rem 0 var(--primary) inset;
  transition: background 0.3s cubic-bezier(0.33, 0.66, 0.66, 1) 0s;
}

.wrap > footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 90%;
}

.home main,
.startseite main {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: var(--gap);
  align-content: flex-start;
  gap: var(--gap);
}
@supports (grid-area: auto) {
  .home main,
  .startseite main {
    display: grid;
  }
}
@media (min-width: 767px) {
  .home main,
  .startseite main {
    grid-template-columns: 1fr 3fr;
  }
}
.home img,
.startseite img {
  max-width: 100%;
  height: auto;
  margin: auto;
  border-radius: var(--radius-blob-2);
  transition: all 1s ease-in-out;
}
.home img:hover,
.startseite img:hover {
  border-radius: var(--radius-blob-3);
}
:lang(de) .home img,
:lang(de) .startseite img {
  border-radius: var(--radius-blob-4);
}
:lang(de) .home img:hover,
:lang(de) .startseite img:hover {
  border-radius: var(--radius-blob-1);
}

@media (prefers-reduced-motion: reduce) {
  :root {
    --animation-duration: 0s !important;
  }
  html {
    scroll-behavior: auto !important;
  }
}
:focus-visible {
  outline: 0.1rem dotted;
}

:focus:not(:focus-visible) {
  outline: none;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

a:focus:hover {
  outline: none;
}

@keyframes smoothscroll1 {
  from, to {
    scroll-behavior: smooth;
  }
}
@keyframes smoothscroll2 {
  from, to {
    scroll-behavior: smooth;
  }
}
html {
  animation: smoothscroll1 var(--animation-duration);
}
html:focus-within {
  animation-name: smoothscroll2;
}