// Import Open Props (without their .css extension)
// https://open-props.style/
@import "./node_modules/open-props/src/props.sizes";
@import "./node_modules/open-props/src/props.fonts";
@import "./node_modules/open-props/src/props.borders";

// open props colors
@import "./node_modules/open-props/src/props.gray";

$breakpoints: (
  "small": (
    min-width: 767px,
  ),
  "medium": (
    min-width: 992px,
  ),
  "large": (
    min-width: 1200px,
  ),
) !default;

:root {
  --gap: 1rem;
  --col: 15rem;
  --accent: var(--gray-8);
  --font-family: var(--font-sans);
  --transparent-white: rgb(255 255 255 / 50%);
  --animation-duration: 1s;
  --loader-begin: 1.5s;
  --loader-duration: 3s;

  color-scheme: light dark;

  // https://web.dev/accent-color/
  accent-color: var(--accent);
}

@media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
  :root {
    --accent: var(--gray-8);
    --border-color: var(--gray-3);
    --link: var(--gray-7);
    --link-higher: var(--gray-7);
    --background-color: var(--gray-0);
    --background-content: #fff;
    --foreground: var(--gray-8);
    --middleground: var(--gray-5);
    --background-higher: var(--bs-white);
    --foreground-higher: var(--gray-9);
    --foreground-lower: var(--gray-7);
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --accent: var(--gray-1);
    --border-color: var(--gray-6);
    --link: var(--gray-0);
    --link-higher: var(--gray-1);
    --background-color: #4b5766;
    --background-content: #48596d;
    --foreground: var(--gray-0);
    --middleground: var(--gray-5);
    --background-higher: var(--gray-9);
    --foreground-higher: var(--bs-white);
    --foreground-lower: var(--gray-1);
  }
}

[data-theme="light"] {
  --accent: var(--gray-8);
  --border-color: var(--gray-3);
  --link: var(--gray-7);
  --link-higher: var(--gray-6);
  --background-color: var(--gray-0);
  --background-content: #fff;
  --foreground: var(--gray-8);
  --middleground: var(--gray-5);
  --background-higher: var(--bs-white);
  --foreground-higher: var(--gray-9);
  --foreground-lower: var(--gray-7);
}

[data-theme="dark"] {
  --accent: var(--gray-1);
  --border-color: var(--gray-6);
  --link: var(--gray-0);
  --link-higher: var(--gray-1);
  --background-color: #4b5766;
  --background-content: #48596d;
  --foreground: var(--gray-2);
  --middleground: var(--gray-5);
  --background-higher: var(--gray-9);
  --foreground-higher: var(--bs-white);
  --foreground-lower: var(--gray-1);
}
