@font-face {
  font-family: GeneralSans;
  src:
    url("../font/General_Sans/GeneralSans-Extralight.woff2") format("woff2"),
    url("../font/General_Sans/GeneralSans-Extralight.woff") format("woff");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: GeneralSans;
  src:
    url("../font/General_Sans/GeneralSans-Regular.woff2") format("woff2"),
    url("../font/General_Sans/GeneralSans-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

body {
  font-feature-settings: "liga", "clig", "kern";
  font-variant-ligatures: common-ligatures;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: GeneralSans, var(--font-sans); // #5
  font-size: var(--font-size-fluid-1);
  font-weight: 200;

  // 1: https://ryanfeigenbaum.com/easy-responsive-typography/
  // 2: https://ryanfeigenbaum.com/fluid-typography/
  // 3: https://royalfig.github.io/fluid-typography-calculator/
  // 4: https://min-max-calculator.9elements.com/
  // 5: https://open-props.style/#typography
}

h1,
h2,
h3 {
  font-weight: 400;
}

a[href^="mailto:"] {
  hyphens: none;
}

p {
  text-align: justify;
  hyphens: auto;
}
