// Accessibility helpers
@media (prefers-reduced-motion: reduce) {
  :root {
    --animation-duration: 0s !important;
  }

  html {
    scroll-behavior: auto !important;
  }
}

:focus-visible {
  outline: 0.1rem dotted;
}

:focus:not(:focus-visible) {
  outline: none;
}

// sections excluded from the tabindex
[tabindex='-1']:focus {
  outline: none !important;
}

// links that are both focused AND hovered
a:focus:hover {
  outline: none;
}

// svg {
//   pointer-events: none;
// }

// smooth scrolling
// https://schepp.dev/posts/smooth-scrolling-and-page-search/

@keyframes smoothscroll1 {
  from,
  to {
    scroll-behavior: smooth;
  }
}

@keyframes smoothscroll2 {
  from,
  to {
    scroll-behavior: smooth;
  }
}

html {
  animation: smoothscroll1 var(--animation-duration);

  &:focus-within {
    animation-name: smoothscroll2;
  }
}
